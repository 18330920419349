@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
    margin: 0%;
    padding: 0%;
    cursor: none;
}

body{
    overflow-x: hidden;
    
}


