$yellow:#fff100;
$pink:#ed1e79;
$red:#d20120;
$brown:#6d3d0f;


$text: "Montserrat", sans-serif;
$heading: "Bangers", cursive;




