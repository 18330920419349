.intro {

    height: calc(100vh - 20px);

    video {

        width: 100%;
        margin-top: -20px;
    }

    div {

        width: 100%;
        height: calc(100vh - 20px);
        position: absolute;
        top: 0%;
    }
}